<template>
  <b-modal
      v-model="visibleModal"
      title="Zmiana numeru telefonu"
      title-class="font-18"
      hide-footer
      centered
      @hide="hideModal"
      @esc="hideModal">
    <div class="row">
      <div class="col-lg-3">
        <div class="form-group auth-form-group-custom mb-2">
          <vue-country-code @onSelect="onSelectCountry" :enabledFlags="true" :enabledCountryCode="true" :preferredCountries="['pl', 'us']" class="custom-select" :class="{ 'is-invalid': $v.form.dialCode.$error }" id="dialCode" style="height: 60px; width: 120px; background: none !important;">
          </vue-country-code>
        </div>
      </div>

      <div class="col-lg-9">
        <div class="form-group auth-form-group-custom mb-2">
          <i class="ri-phone-line auti-custom-input-icon"></i>
          <label for="phoneNumber">{{ $t('message.phone-number')}} </label>

          <input
              v-model="form.phoneNumber"
              type="text"
              class="form-control"
              id="phoneNumber"
              :placeholder="$t('message.type', { 'type': $t('message.phone-number').toLowerCase()})"
              :class="{ 'is-invalid': submitted && ($v.form.phoneNumber.$error || !isPossibleNumber()) }"/>

          <div v-if="!$v.form.phoneNumber.required" class="invalid-feedback">{{ $t('message.required')}}</div>
          <div v-if="!isPossibleNumber()" class="invalid-feedback">{{ $t('message.number-error')}}</div>
        </div>
      </div>
    </div>

    <div class="text-right">
      <b-button @click="changePhoneNumber" variant="danger">Zmień numer telefonu</b-button>
      <b-button class="ml-1" variant="secondary" @click="hideModal">{{  $t('message.cancel') }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import parsePhoneNumber from "libphonenumber-js";
import {required} from "vuelidate/lib/validators";
import axios from "axios";
import Swal from "sweetalert2";
import {errorCatcher} from "../helpers/error-catcher";

export default {
  data() {
    return {
      submitted: false,

      visibleModal: false,
      form: {
        dialCode: "",
        phoneNumber: ""
      }
    }
  },

  validations: {
    form: {
      dialCode: { required },
      phoneNumber: { required },
    }
  },

  methods: {
    openModal() {
      this.visibleModal = true
    },

    hideModal() {
      this.submitted = false
      this.$v.form.$reset()
      this.visibleModal = false
      this.form.dialCode = ""
      this.form.phoneNumber = ""
    },

    onSelectCountry({dialCode}) {
      this.form.dialCode = dialCode
    },

    isPossibleNumber() {
      if (!this.form.dialCode || !this.form.phoneNumber) {
        return false
      }

      const phoneNumber = parsePhoneNumber("+" + this.form.dialCode + this.form.phoneNumber)
      if (!phoneNumber) {
        return false
      }

      return phoneNumber.isValid()
    },

    async resendCode() {
      const json = {
        type: "PHONE_NUMBER"
      }

      try {
        await axios.post(`/user/verification/send`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });
      } catch (error) {
        errorCatcher.catchErrors(error);
      }
    },

    async changePhoneNumber() {
      this.submitted = true
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      if (!this.isPossibleNumber()) {
        return
      }

      const json = {
        dialCode: "+" + this.form.dialCode,
        phoneNumber: this.form.phoneNumber,
      }

      try {
        await axios.post(`/user/update-phone-number`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
        })

        await Swal.fire("Sukces!", "Numer został ustawiony. Zweryfikuj swój numer telefonu.", "success");
        await this.resendCode(false)
        this.hideModal()
      } catch (error) {
        errorCatcher.catchErrors(error);
      }
    }
  }

}
</script>